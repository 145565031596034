/**
 * Main Component for the Mathsolutely application. Sets up screen layout, color theme, etc.
 */

// Third Party
import React from "react";
import ResizeDetector from "react-resize-detector";

// Mathsolutely
import AppMain from "../AppMain";

/**
 * Main Component. Adjusts screen layout based on window size
 */
const Main = () => {
  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        // You can pass width to AppMain if needed for layout adjustments
        <AppMain />
      )}
    />
  );
};

export default Main;
